import s from "./Cabinet.module.css";
import {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Report2024_6 = () => {
    const [respData, setData] = useState({
        p1: "",
        p2: "",
        p3: "",
        p4: "",
        p5: "",
        p6: "",
        p7: "",
        p8: "",
        p9: "",
        p10: "",
        p11: "",
        p12: "",
        p13: "",
        p14: "",
        p15: "",
        p16: "",
        p17: "",
        p18: "",
        p19: "",
        p20: "",
        p21: "",
        p22: "",
        p23: "",
        p24: "",
        p25: "",
        p26: "",
        p27: "",
        p28: "",
        p29: "",
        p30: "",
        p31: "",
        p32: "",
        p33: "",
        p34: "",
        p35: "",
        p36: "",
        p37: "",
        p38: "",
        p39: "",
        p40: "",
        p41: "",
        p42: "",
        p43: "",
        p44: "",
        p45: "",
        p46: "",
        p47: "",
        p48: "",
        p49: "",
        p50: "",
        контакт_данные: "",
        comm: "",
        регион: "",
        примечание: "",
    });
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (user.id) {
                let config = {
                    method: "GET",
                    maxBodyLength: Infinity,
                    url: `https://frcopeca.ru/api/housefacts2?id=${user.id}`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                };
                try {
                    const response = await axios.request(config);
                    if (response.data[0]) {
                        setData(response.data[0]);
                    }
                } catch (error) {
                    setData({
                        p1: "",
                        p2: "",
                        p3: "",
                        p4: "",
                        p5: "",
                        p6: "",
                        p7: "",
                        p8: "",
                        p9: "",
                        p10: "",
                        p11: "",
                        p12: "",
                        p13: "",
                        p14: "",
                        p15: "",
                        p16: "",
                        p17: "",
                        p18: "",
                        p19: "",
                        p20: "",
                        p21: "",
                        p22: "",
                        p23: "",
                        p24: "",
                        p25: "",
                        p26: "",
                        p27: "",
                        p28: "",
                        p29: "",
                        p30: "",
                        p31: "",
                        p32: "",
                        p33: "",
                        p34: "",
                        p35: "",
                        p36: "",
                        p37: "",
                        p38: "",
                        p39: "",
                        p40: "",
                        p41: "",
                        p42: "",
                        p43: "",
                        p44: "",
                        p45: "",
                        p46: "",
                        p47: "",
                        p48: "",
                        p49: "",
                        p50: "",
                        контакт_данные: "",
                        comm: "",
                        регион: "",
                        примечание: "",
                    });
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [user.id]);

    if (loading) {
        return <div className={s.main}>Загрузка...</div>;
    }

    return (
        <div className={s.main}>
            <h1>
                Форма предоставления сведений о выявлении и устройстве детей-сирот и детей, оставшихся без попечения
                родителей, за 3 квартала 2024 г.
            </h1>
            {respData.статус && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "28px",
                        fontWeight: "bold",
                        color: "#000000",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Статус: {respData.статус}
                </div>
            )}
            {respData.комментарии && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "24px",
                        fontWeight: "normal",
                        color: "red",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Комментарий от проверяющего:
                    <br/>
                    {respData.комментарии}
                </div>
            )}
            <ReportForm respData={respData}/>
            {/* <AddForm/> */}
        </div>
    );
};
export default Report2024_6;

const ReportForm = ({respData}) => {
    return (
        <div>
            <Report2 respData={respData}/>
        </div>
    );
};

const Report2 = ({respData}) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const readOnly = respData.статус === "Опубликовано";
    const readOnlyRegion = respData.статус !== "" || respData.статус !== null
    const [err, setErr] = useState("");
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                p1: respData.p1,
                p2: respData.p2,
                p3: respData.p3,
                p4: respData.p4,
                p5: respData.p5,
                p6: respData.p6,
                p7: respData.p7,
                p8: respData.p8,
                p9: respData.p9,
                p10: respData.p10,
                p11: respData.p11,
                p12: respData.p12,
                p13: respData.p13,
                p14: respData.p14,
                p15: respData.p15,
                p16: respData.p16,
                p17: respData.p17,
                p18: respData.p18,
                p19: respData.p19,
                p20: respData.p20,
                p21: respData.p21,
                p22: respData.p22,
                p23: respData.p23,
                p24: respData.p24,
                p25: respData.p25,
                p26: respData.p26,
                p27: respData.p27,
                p28: respData.p28,
                p29: respData.p29,
                p30: respData.p30,
                p31: respData.p31,
                p32: respData.p32,
                p33: respData.p33,
                p34: respData.p34,
                p35: respData.p35,
                p36: respData.p36,
                p37: respData.p37,
                p38: respData.p38,
                p39: respData.p39,
                p40: respData.p40,
                p41: respData.p41,
                p42: respData.p42,
                p43: respData.p43,
                p44: respData.p44,
                p45: respData.p45,
                p46: respData.p46,
                p47: respData.p47,
                p48: respData.p48,
                p49: respData.p49,
                p50: respData.p50,
                контакт_данные: respData.контакт_данные,
                примечание: respData.примечание,

                comm: respData.комментарии,
                регион: respData.регион,
            }}
            onSubmit={(values) => {
                let data2 = new FormData();
                // debugger;
                data2.append("p1", values.p1);
                data2.append("p2", values.p2);
                data2.append("p3", values.p3);
                data2.append("p4", values.p4);
                data2.append("p5", values.p5);
                data2.append("p6", values.p6);
                data2.append("p7", values.p7);
                data2.append("p8", values.p8);
                data2.append("p9", values.p9);
                data2.append("p10", values.p10);
                data2.append("p11", values.p11);
                data2.append("p12", values.p12);
                data2.append("p13", values.p13);
                data2.append("p14", values.p14);
                data2.append("p15", values.p15);
                data2.append("p16", values.p16);
                data2.append("p17", values.p17);
                data2.append("p18", values.p18);
                data2.append("p19", values.p19);
                data2.append("p20", values.p20);
                data2.append("p21", values.p21);
                data2.append("p22", values.p22);
                data2.append("p23", values.p23);
                data2.append("p24", values.p24);
                data2.append("p25", values.p25);
                data2.append("p26", values.p26);
                data2.append("p27", values.p27);
                data2.append("p28", values.p28);
                data2.append("p29", values.p29);
                data2.append("p30", values.p30);
                data2.append("p31", values.p31);
                data2.append("p32", values.p32);
                data2.append("p33", values.p33);
                data2.append("p34", values.p34);
                data2.append("p35", values.p35);
                data2.append("p36", values.p36);
                data2.append("p37", values.p37);
                data2.append("p38", values.p38);
                data2.append("p39", values.p39);
                data2.append("p40", values.p40);
                data2.append("p41", values.p41);
                data2.append("p42", values.p42);
                data2.append("p43", values.p43);
                data2.append("p44", values.p44);
                data2.append("p45", values.p45);
                data2.append("p46", values.p46);
                data2.append("p47", values.p47);
                data2.append("p48", values.p48);
                data2.append("p49", values.p49);
                data2.append("p50", values.p50);
                data2.append("контакт_данные", values.контакт_данные);
                data2.append("регион", values.регион);
                data2.append("статус", "На утверждении")
                data2.append("примечание", values.примечание);
                data2.append("author", user.id);
                let config = {
                    method: respData.регион ? "patch" : "POST",
                    maxBodyLength: Infinity,

                    url: `https://frcopeca.ru/api/housefacts2?id=${user.id}`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                    data: data2,
                };
                axios.request(config)
                    .then(() => {
                        setErr("");
                        navigate("/profile");
                    })
                    .catch((e) => {
                        const errorMessage = e.response
                            ? e.response.data?.message || e.response.statusText || "Ошибка на сервере, попробуйте проверить данные или обратитесь в поддержку"
                            : e.message || JSON.stringify(e);

                        setErr(errorMessage);


                        console.error("Ошибка:", e);
                    });
            }}
        >
            {(errors, touched) => (
                <Form className={s.reportForm}>
                    <label htmlFor="регион">Субъект Российской Федерации</label>
                    <Field readOnly={readOnlyRegion}
                           id="регион"
                           name="регион"
                           as="textarea"
                           required={true}
                    />

                    <label>
                        Раздел 1. Выявление и устройство детей, оставшихся без попечения
                        родителей
                    </label>

                    <label htmlFor="p1">
                        1. Всего детей выявлено и учтено на конец отчетного периода (сумма
                        строк 2 - 13 )
                    </label>
                    <Field readOnly={readOnly} id="p1" name="p1" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p2">2. из них (из строки 1): детей-сирот</label>
                    <Field readOnly={readOnly} id="p2" name="p2" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p3">
                        3. численность детей, у которых лишены родительских прав оба
                        родителя или единственный родитель
                    </label>
                    <Field readOnly={readOnly} id="p3" name="p3" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p4">
                        4. численность детей, у которых ограничены в родительских правах оба
                        родителя или единственный родитель
                    </label>
                    <Field readOnly={readOnly} id="p4" name="p4" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p5">
                        5. численность детей, у которых оба родителя или единственный
                        родитель признаны недееспособными (ограниченно дееспособными)
                    </label>
                    <Field readOnly={readOnly} id="p5" name="p5" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p6">
                        6. численность детей, родители которых находятся под стражей или
                        отбывают наказания в виде лишения свободы
                    </label>
                    <Field readOnly={readOnly} id="p6" name="p6" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p7">
                        7. численность детей, у которых оба родителя или единственный
                        родитель объявлены в розыск
                    </label>
                    <Field readOnly={readOnly} id="p7" name="p7" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p8">
                        8. численность детей, оставленных родителями в организациях по
                        окончанию срока пребывания
                    </label>
                    <Field readOnly={readOnly} id="p8" name="p8" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p9">
                        9. численность детей, отобранных у родителей (единственного
                        родителя)
                    </label>
                    <Field readOnly={readOnly} id="p9" name="p9" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p10">
                        10. численность детей, оставленных матерями (родителями) при
                        рождении
                    </label>
                    <Field readOnly={readOnly} id="p10" name="p10" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p11">
                        11. численность детей, у которых оба родителя или
                        единственный родитель неизвестны
                    </label>
                    <Field readOnly={readOnly} id="p11" name="p11" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p12">
                        12. численность детей, в отношении которых факт отсутствия
                        родительского попечения установлен решением суда
                    </label>
                    <Field readOnly={readOnly} id="p12" name="p12" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p13">13. иное</label>
                    <Field readOnly={readOnly} id="p13" name="p13" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p14">
                        14. Всего детей устроены (из стр. 1) (сумма строк
                        15-18, 24-26)
                    </label>
                    <Field readOnly={readOnly} id="p14" name="p14" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p15">
                        15. из них (из стр. 14) устроены: под надзор в организации для
                        детей-сирот и детей, оставшихся без попечения родителей
                        (образовательные, медицинские организации, в организации,
                        оказывающие социальные услуги, в негосударственные организации, в
                        детские дома семейного типа и другие)
                    </label>
                    <Field readOnly={readOnly} id="p15" name="p15" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p16">
                        16. обучаются в профессиональных образовательных организациях и
                        образовательных организациях высшего образования
                    </label>
                    <Field readOnly={readOnly} id="p16" name="p16" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p17">
                        17. находятся под предварительной опекой на конец отчетного периода
                    </label>
                    <Field readOnly={readOnly} id="p17" name="p17" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p18">18. под опеку (попечительство) (сумма строк 19-20)</label>
                    <Field readOnly={readOnly} id="p18" name="p18" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p19">
                        19. из них (из строки 18): переданные на безвозмездную форму опеки
                        (попечительства)
                    </label>
                    <Field readOnly={readOnly} id="p19" name="p19" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p20">
                        20. переданные на возмездную форму опеки (попечительства) (сумма строк 21-23)
                    </label>
                    <Field readOnly={readOnly} id="p20" name="p20" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p21">
                        21. в том числе (из строки 20): под опеку (попечительство) по
                        договору о приемной семье
                    </label>
                    <Field readOnly={readOnly} id="p21" name="p21" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p22">
                        22. под опеку (попечительство) по договору о патронатной семье
                        (патронате, патронатном воспитании) в случаях, предусмотренных
                        законами субъектов Российской Федерации
                    </label>
                    <Field readOnly={readOnly} id="p22" name="p22" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p23">
                        23. на иные виды возмездной опеки (попечительства)
                    </label>
                    <Field readOnly={readOnly} id="p23" name="p23" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p24">24. на усыновление (удочерение)</label>
                    <Field readOnly={readOnly} id="p24" name="p24" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p25">25. возвращены родителям</label>
                    <Field readOnly={readOnly} id="p25" name="p25" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p26">26. иное</label>
                    <Field readOnly={readOnly} id="p26" name="p26" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p27">
                        27. Численность детей, оставшихся неустроенными на конец отчетного
                        периода (из строки 1) (показатель из строки 1 минус показатель строки 14)
                    </label>
                    <Field readOnly={readOnly} id="p27" name="p27" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label>
                        Раздел 2. Устройство детей, оставшихся без попечения родителей, на
                        воспитание в семьи граждан
                    </label>

                    <label htmlFor="p28">
                        28. Численность детей, оставшихся без попечения родителей,
                        переданных в семью на воспитание, на конец отчетного периода (сумма
                        стр. 29, стр. 30, стр. 37, стр. 40, стр. 41){" "}
                    </label>
                    <Field readOnly={readOnly} id="p28" name="p28" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p29">
                        29. из них (из строки 28): на безвозмездную форму опеки
                        (попечительства)
                    </label>
                    <Field readOnly={readOnly} id="p29" name="p29" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p30">
                        30. на возмездную форму опеки (попечительства) (сумма строк 31-33)
                    </label>
                    <Field readOnly={readOnly} id="p30" name="p30" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p31">
                        31. из них (из стр. 30): по договору о приемной семье
                    </label>
                    <Field readOnly={readOnly} id="p31" name="p31" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p32">32. по договору о патронатной семье</label>
                    <Field readOnly={readOnly} id="p32" name="p32" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p33">
                        33. на иные виды возмездной опеки (попечительства)
                    </label>
                    <Field readOnly={readOnly} id="p33" name="p33" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p34">
                        34. в том числе (из стр. 33): за счет средств субъекта Российской
                        Федерации{" "}
                    </label>
                    <Field readOnly={readOnly} id="p34" name="p34" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p35">
                        35. за счет доходов от имущества подопечного{" "}
                    </label>
                    <Field readOnly={readOnly} id="p35" name="p35" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p36">36. за счет средств третьих лиц</label>
                    <Field readOnly={readOnly} id="p36" name="p36" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p37">37. на усыновление (удочерение) (сумма строк 38-39)</label>
                    <Field readOnly={readOnly} id="p37" name="p37" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p38">
                        38. из них (из стр. 37): гражданами России
                    </label>
                    <Field readOnly={readOnly} id="p38" name="p38" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p39">39. иностранными гражданами </label>
                    <Field readOnly={readOnly} id="p39" name="p39" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p40">
                        40. находящихся под предварительной опекой
                        (попечительством), на конец отчетного периода
                    </label>
                    <Field readOnly={readOnly} id="p40" name="p40" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p41">
                        41. выбывших к родителям из организаций для детей-сирот и детей,
                        оставшихся без попечения родителей, в течение отчетного периода
                    </label>
                    <Field readOnly={readOnly} id="p41" name="p41" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p42">
                        42. Численность детей, оставшихся без попечения родителей,
                        находящихся на воспитании в семьях (всего в регионе, по состоянию на конец отчетного периода )
                    </label>
                    <Field readOnly={readOnly} id="p42" name="p42" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label>
                        Раздел 3. Устройство детей в организации для детей-сирот и детей,
                        оставшихся без попечения родителей
                    </label>

                    <label htmlFor="p43">
                        3. Численность детей, находящихся в организациях для детей-сирот и
                        детей, оставшихся без попечения родителей, на конец отчетного
                        периода (сумма стр. 44 , стр. 45 , стр. 46)
                    </label>
                    <Field readOnly={readOnly} id="p43" name="p43" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p44">
                        44. из них (из строки 43): находящихся под надзором{" "}
                    </label>
                    <Field readOnly={readOnly} id="p44" name="p44" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p45">
                        45. в целях обеспечения временного проживания в течение периода,
                        когда законные представители по уважительным причинам не могут
                        исполнять свои обязанности в отношении ребенка
                    </label>
                    <Field readOnly={readOnly} id="p45" name="p45" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p46">
                        46. в целях получения ими медицинских, социальных, образовательных
                        или иных услуг
                    </label>
                    <Field readOnly={readOnly} id="p46" name="p46" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p47">
                        47. с ограниченными возможностями здоровья (в том числе с
                        инвалидностью)
                    </label>
                    <Field readOnly={readOnly} id="p47" name="p47" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p48">48. в возрасте от 10 до 18 лет</label>
                    <Field readOnly={readOnly} id="p48" name="p48" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p49">
                        49. полнородных и неполнородных братьев и сестер, совместно
                        находящихся в одной организации для детей-сирот и детей, оставшихся
                        без попечения родителей
                    </label>
                    <Field readOnly={readOnly} id="p49" name="p49" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p50">
                        50. Численность лиц из числа детей, завершивших пребывание в
                        организации для детей-сирот, но не старше 23 лет, временно бесплатно
                        проживающих в организациях для детей-сирот и детей, оставшихся без
                        попечения родителей, на конец отчетного периода
                    </label>
                    <Field readOnly={readOnly} id="p50" name="p50" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="примечание">Примечание:</label>
                    <Field readOnly={readOnly} id="примечание" name="примечание" as="textarea"/>

                    <label htmlFor="контакт_данные">
                        Контактные данные ответственного за заполнение формы мониторинга
                        (наименование ОИВ, Ф.И.О., должность, телефон (с указанием кода
                        региона), адрес электронной почты)
                    </label>
                    <Field readOnly={readOnly} id="контакт_данные" name="контакт_данные" as="textarea" required={true}/>
                    <p></p>
                    <button type="submit">Отправить</button>
                    <p></p>
                    {respData.file && <a href={respData.file}>Скачать файл</a>}
                    <p></p>
                    <div style={{color: "red"}}>{err}</div>
                </Form>
            )}
        </Formik>
    );
};